import { phone, phoneWhatsApp } from '../../global/contants';
import { Icons } from '../Icons';
import './style.scss';

const Rodape = () => {
  return (
    <footer>
      <div className="container py-3">
        <div className="row text-center">
          <img
            alt="Logo"
            src="/logo-footer.png"
            className="img-fluid mb-2 d-inline-block mx-auto"
            style={{ width: 275 }}
          />
          <span className="fs-3 fw-bold d-block lead">
            Para mais informações entrar em contato pelo telefone:
          </span>

          <a
            href={`https://wa.me/${phoneWhatsApp}`}
            target="_blank"
            rel="noopener noreferrer"
            className="d-inline-block mx-auto fs-3 fw-bold"
            style={{ textDecoration: 'none' }}>
            <Icons.whatsapp style={{ height: 28, width: 28, fill: '#fff' }} />
            <span>{phone}</span>
          </a>
        </div>
      </div>
      <div
        className="bg-white text-center text-uppercase fw-bold text-primary py-2"
        style={{ fontSize: 12 }}>
        TODOS OS DIREITOS RESERVADOS PARA CLÍNICA AMOR A VIDA <br />
        SITE DESENVOLVIDO POR: DATA CONTROL INFORMÁTICA
      </div>
    </footer>
  );
};

export default Rodape;
