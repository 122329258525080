/* eslint-disable no-undef */
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelpRoute } from './components/help-route';

import Home from './pages/Home';
import Equipe from './pages/Equipe';
import Contato from './pages/Contato';
import Galeria from './pages/Galeria';
import Ibitinga from './pages/Ibitinga';
import Itapolis from './pages/Itapolis';
import QuemSomos from './pages/QuemSomos';
import NossosServicos from './pages/NossosServicos';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<HelpRoute element={<Home />} title={process.env.REACT_APP_TITLE} />}
          />
          <Route
            path="/quem-somos"
            element={
              <HelpRoute element={<QuemSomos />} title={`Quem Somos - ${process.env.REACT_APP_TITLE}`} />
            }
          />
          <Route
            path="/nossa-equipe"
            element={
              <HelpRoute element={<Equipe />} title={`Nossa Esquipe - ${process.env.REACT_APP_TITLE}`} />
            }
          />
          <Route
            path="/nossos-servicos"
            element={
              <HelpRoute
                element={<NossosServicos />}
                title={`Nossos Serviços - ${process.env.REACT_APP_TITLE}`}
              />
            }
          />

          <Route
            path="/contato"
            element={
              <HelpRoute element={<Contato />} title={`Contato - ${process.env.REACT_APP_TITLE}`} />
            }
          />
          <Route
            path="/nossas-unidades"
            element={
              <HelpRoute
                element={<Galeria />}
                title={`Nossas Unidades - ${process.env.REACT_APP_TITLE}`}
              />
            }
          />
          <Route
            path="/como-chegar/itapolis"
            element={
              <HelpRoute
                element={<Itapolis />}
                title={`Como Chegar/Itápolis - ${process.env.REACT_APP_TITLE}`}
              />
            }
          />
          <Route
            path="/como-chegar/ibitinga"
            element={
              <HelpRoute
                element={<Ibitinga />}
                title={`Como Chegar/Ibitinga - ${process.env.REACT_APP_TITLE}`}
              />
            }
          />
          <Route
            path="*"
            element={<HelpRoute element={<Home />} title={process.env.REACT_APP_TITLE} />}
          />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
