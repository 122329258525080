import * as React from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';

interface Props extends HelmetProps {
  element?: React.ReactNode | null;
}

export function HelpRoute({ element, ...restHelmet }: Props) {
  return (
    <>
      {element}
      <Helmet {...restHelmet} />
    </>
  );
}
