import './style.scss';

import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { HashLink } from 'react-router-hash-link';
import scrollToElement from 'scroll-to-element';

import LogoImage from './logo.png';
import { Icons } from '../Icons';
import { Flip } from '../Flip';
import { phoneWhatsApp } from '../../global/contants';

const Topo = () => {
  const { pathname } = useLocation();
  const addAbsolue = pathname.split('/')[1];
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Link
        to={'/'}
        className={`${scrollPosition === 0 && 'd-none'} text-primary scrolling-top`}
        rel="noopener noreferrer"
        onClick={() =>
          scrollToElement('#root', {
            offset: 0,
            duration: 1500
          })
        }
        style={{ zIndex: 9999, textDecoration: 'none', cursor: 'pointer' }}>
        <Icons.circleup style={{ width: 32, height: 32, fill: '#222456' }} />
      </Link>

      <Link
        to={`https://wa.me/${phoneWhatsApp}`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-top">
        <Icons.whatsapp style={{ width: 32, height: 32, fill: '#fff' }} />
      </Link>

      <header
        className={`${addAbsolue === '' ? 'fixed-top' : 'bg-white'} ${
          scrollPosition > 0 && addAbsolue === '' && 'set-scrol'
        }`}>
        {/* menus for mobile */}
        <div className="dropdown px-3 py-1 d-block d-sm-none">
          <div className="row">
            <div className="col col-4">
              <button
                type="button"
                id="menu_dropdown"
                className="btn btn-secondary"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <Icons.bars style={{ width: 22, height: 22 }} />
              </button>
              <ul className="dropdown-menu personalize" aria-labelledby="menu_dropdown">
                <li>
                  <HashLink
                    type="button"
                    className="dropdown-item"
                    to="/#quem_somos"
                    onClick={() =>
                      scrollToElement('#quem_somos', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    Quem Somos
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    type="button"
                    className="dropdown-item"
                    to="/#nossos_servicos"
                    onClick={() =>
                      scrollToElement('#nossos_servicos', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    Serviços
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    className="dropdown-item"
                    type="button"
                    to="/#nossas_unidades"
                    onClick={() =>
                      scrollToElement('#nossas_unidades', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    Unidades
                  </HashLink>
                </li>
                <li>
                  <Link
                    to="/#nossas_equipes"
                    className="dropdown-item"
                    onClick={() =>
                      scrollToElement('#nossas_equipes', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    Nossa Equipe
                  </Link>
                </li>
                <li>
                  <HashLink
                    className="dropdown-item"
                    type="button"
                    to="/#contato"
                    onClick={() =>
                      scrollToElement('#contato', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    Contato
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="col col-4 text-center">
              <Link to="/" className="navbar-brand">
                <img src="/logo.png" alt="Logo" className="img-fluid" style={{ width: 155 }} />
              </Link>
            </div>
            <div className="col col-4"></div>
          </div>
        </div>

        {/* menus for desktop */}
        <div className="container d-none d-sm-block p-3 px-0">
          <div className="row align-items-center justify-content-between">
            <div className="col col-md-3">
              <Link
                to="/"
                rel="noopener noreferrer"
                onClick={() =>
                  scrollToElement('#root', {
                    offset: 0,
                    duration: 1500
                  })
                }>
                <img src={LogoImage} alt="Logo" className="img-fluid" />
              </Link>
            </div>
            <div className="col col-md-7">
              <nav className="d-flex align-items-center text-uppercase">
                <Flip.body
                  handleScroll={() =>
                    scrollToElement('#quem_somos', {
                      offset: 0,
                      duration: 1500
                    })
                  }
                  hashLink="#quem_somos">
                  <Flip.link label="quem somos" to="/#quem_somos" />
                </Flip.body>

                <Flip.body
                  hashLink="#nossos_servicos"
                  handleScroll={() =>
                    scrollToElement('#nossos_servicos', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  <Flip.link label="serviços" to="/#nossos_servicos" />
                </Flip.body>

                <Flip.body
                  hashLink="#nossas_unidades"
                  handleScroll={() =>
                    scrollToElement('#nossas_unidades', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  <Flip.link label="unidades" to="/#nossas_unidades" />
                </Flip.body>

                <Flip.body
                  hashLink="#nossas_equipes"
                  handleScroll={() =>
                    scrollToElement('#nossas_equipes', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  <Flip.link label="equipe" to="/#nossas_equipes" />
                </Flip.body>

                <Flip.body
                  hashLink="#contato"
                  handleScroll={() =>
                    scrollToElement('#contato', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  <Flip.link label="contato" to="/#contato" />
                </Flip.body>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Topo;
