import * as React from 'react';
import { cn } from '../utils';

type Props = {
  title: string;
  className?: string;
};

const classDefault = 'mb-3 mt-5 fw-bold fs-1 text-uppercase text-primary';

export const TitleH1: React.FC<Props> = ({ title, ...restTitle }) => (
  <h1 className={`${cn(classDefault, restTitle.className)}`}>{title}</h1>
);

export const TitleH2: React.FC<Props> = ({ title, ...restTitle }) => (
  <h2 className={`${cn(classDefault, restTitle.className)}`}>{title}</h2>
);

export const TitleH3: React.FC<Props> = ({ title, ...restTitle }) => (
  <h3 className={`${cn(classDefault, restTitle.className)}`}>{title}</h3>
);

export const TitleH4: React.FC<Props> = ({ title, ...restTitle }) => (
  <h4 className={`${cn(classDefault, restTitle.className)}`}>{title}</h4>
);
