import './style.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import scrollToElement from 'scroll-to-element';
import { Link } from 'react-router-dom';

import Topo from '../../components/Topo';
import Rodape from '../../components/Rodape';
import Banner from '../../components/Banner';
import { phoneWhatsApp } from '../../global/contants';

const Home = () => {
  return (
    <>
      <Topo />

      <Banner />

      {/* quem somos */}
      <div className="info info-quem-somos" id="quem_somos">
        <div className="container d-flex" style={{ height: '100%' }}>
          <div className="row align-items-center">
            <div className="col-12 col-md-5 align-self-center p-1">
              <img src="/amoravida-sobre.jpg" style={{ border: 'solid 6px #65bbd8', width: '100%' }} />
            </div>
            <div className="col-12 col-md-7 align-self-center">
              <ScrollAnimation animateIn="fadeInDownBig" animateOut="fadeInDown">
                <h2>Clínica Amor a Vida</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUpBig" animateOut="fadeInDown">
                <div className="text-center text-uppercase">
                  Há 10 anos no mercado, encaminhando <br />
                  dependentes químicos e alcoolistas para <br /> clínicas regularizadas com tratamento
                  <br />
                  humanizado e com muito amor ao próximo, <br />
                  promovendo sua reabilitação, reinserção social, <br />
                  e retorno aos seus familiares,
                  <br />
                  enfatizando a valorização pessoal e <br />
                  familiar.
                  <br />
                  <Link
                    to="/quem-somos"
                    className="mt-5 fs-6 d-block float-end fw-bold"
                    onClick={() =>
                      scrollToElement('#root', {
                        offset: 0,
                        duration: 1500
                      })
                    }>
                    VEJA MAIS...
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      {/* nossos servicos */}
      <div className="info info-servicos" id="nossos_servicos">
        <div className="container d-flex justify-content-start" style={{ height: '100%' }}>
          <div className="row align-self-center">
            <div className="col-12 p-5 p-sm-0">
              <ScrollAnimation
                animateIn="fadeInUpBig"
                animateOut="fadeInDown"
                className="row justify-content-center">
                <h2>nossos serviços</h2>
                <ul>
                  <li>Tratamento de dependentes químicos</li>
                  <li>Tratamento de Alcoolismo</li>
                  <li>Internação voluntária ou involuntaária</li>
                  <li>Tratamento Masculino</li>
                  <li>Tratamento Feminino</li>
                </ul>

                <Link
                  to="/nossos-servicos"
                  className="mt-5 fs-6 d-block float-end fw-bold"
                  onClick={() =>
                    scrollToElement('#root', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  MAIS DETALHES...
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      {/* nossas unidades */}
      <div className="info info-unidades" id="nossas_unidades">
        <div className="container d-flex justify-content-end" style={{ height: '100%' }}>
          <div className="row align-self-center">
            <div className="col p-5 p-sm-0">
              <ScrollAnimation animateIn="fadeInUpBig" animateOut="fadeInDown" className="text-center">
                <h2>unidades</h2>
                Temos parceria com diversas clínicas do brasil <br />
                em diferentes regiões. <br />
                todas elas com excelentes acomodações e <br />
                grande variedade de atividades tanto físicas <br />
                como mentais e espirituais, transformando os <br />
                seus dias em dias de muita felicidade e com <br />
                muito amor ao próximo.
                <br />
                <Link
                  to="/nossas-unidades"
                  className="mt-5 fs-6 d-block float-end fw-bold"
                  onClick={() =>
                    scrollToElement('#root', {
                      offset: 0,
                      duration: 1500
                    })
                  }>
                  CONHEÇA ALGUMAS CLÍNICAS...
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      {/* nossas equipes */}
      <div className="info info-equipes" id="nossas_equipes">
        <div className="container d-flex justify-content-start" style={{ height: '100%' }}>
          <div className="row align-self-center">
            <div className="col p-5 p-sm-0">
              <ScrollAnimation animateIn="fadeInUpBig" animateOut="fadeInDown">
                <h2>equipe</h2>
                <ul>
                  <li>Profissionais Especializados</li>
                  <li>Médico e Terapeutas</li>
                  <li>Psicólogos e Psiquiatras</li>
                  <li>Equipe Multidiplicinar</li>
                  <li>Coordenador 24 horas</li>
                  <li>Monitores 24 horas</li>
                </ul>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>

      {/* <Contato /> */}

      <div className="info info-contato" id="contato">
        <div className="container d-flex justify-content-start" style={{ height: '100%' }}>
          <div className="row justify-content-center align-items-center">
            <ScrollAnimation animateIn="fadeInUpBig" animateOut="fadeInDown">
              <h1 className="mb-0 pb-3 fw-bold text-center fs-1 text-uppercase text-primary">
                atendimento e remoção 24h
              </h1>
              <div className="mb-5 mt-0 text-center fst-bold text-uppercase text-secondary fs-4">
                entre em contato conosco, nós podemos te ajudar!
              </div>
              <div className="row align-self-center d-flex">
                <div className="col-12 col-sm-7">
                  <img src="/amoravida-ambulancia.png" className="img-fluid" alt="Entre em Contato" />
                </div>
                <div className="col-12 col-sm-5 align-self-center bg-info p-5 fw-bold rounded-3">
                  Temos uma equipe altamamente qualificada
                  <br /> e pronta para te ajudar!
                  <br />
                  <br />
                  Queremos muito ouvir a sua história, <br />
                  entre em contato com a gente!
                  <Link
                    to={`https://wa.me/${phoneWhatsApp}`}
                    target="_blank"
                    className="btn btn-secondary btn-lg text-white fs-1 px-5 mt-5">
                    {/* <Icons.whatsapp style={{ height: 48, width: 48, fill: '#fff', margin: 0 }} /> */}
                    <span>Falar no WhatsApp</span>
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      {/* <div className="info">
        <MapPage />
      </div> */}

      <Rodape />
    </>
  );
};

export default Home;
