import './style.scss';

import Rodape from '../../components/Rodape';
import Topo from '../../components/Topo';
import { Link } from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
// import { useEffect, useState } from 'react';
// import { PropsGallery } from '../../global/types';
import { TitleH1, TitleH4 } from '../../components/title';
import { phoneWhatsApp } from '../../global/contants';

const QuemSomos = () => {
  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [arrOfImages, setArrOfImages] = useState<string[]>([]);
  // const [indexOfImages, setIndexOfImages] = useState(0);
  // const [images, setImages] = useState<PropsGallery[]>([]);

  // const handleOpenModal = (index: number) => {
  //   setIndexOfImages(index);
  //   setShowModal(true);
  //   return;
  // };

  // const galeriaImgs = (int: number, title: string, dir: string) => {
  //   for (let i: number = 1; i <= int; i++) {
  //     let arr = { i: i, title: title, dir: dir };
  //     setImages((old) => [...old, arr]);

  //     let arrDir = `/${dir}/img-(${i}).jpg`;
  //     setArrOfImages((old: any) => [...old, arrDir]);
  //   }
  // };

  // useEffect(() => {
  //   galeriaImgs(10, 'Galeria de Fotos - Quem Somos', 'quem_somos');
  // }, []);

  // const GaleriaQuemSomos = ({ images }: { images: PropsGallery[] }) => (
  //   <div className="row justify-content-center">
  //     <h1 className="fst-italic mt-5 mb-5 pb-3 col col-12 border-bottom">{images?.[0]?.title}</h1>
  //     {images.map((img, i) => (
  //       <div
  //         key={i}
  //         className="col-12 col-sm-3 img mb-5"
  //         style={{ cursor: 'pointer' }}
  //         onClick={() => handleOpenModal(i)}>
  //         <img src={`/${img.dir}/xs/img-(${img.i}).jpg`} alt={img.title} style={{ width: '100%' }} />
  //       </div>
  //     ))}
  //   </div>
  // );

  return (
    <>
      <Topo />
      <div className="bg-white border-top pt-5 pb-5">
        {/* <h1 className="mb-5 mt-3 fw-bold text-center fs-1 text-uppercase text-primary">QUEM SOMOS</h1> */}

        <TitleH1 title="QUEM SOMOS" className="fst-bold fs-1 text-center" />

        <div className="quem-somos-image mb-5" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 text-justify">
              <TitleH4 title="CLÍNICA AMOR A VIDA" className="fst-italic fs-4 text-start" />
              <p>
                Há 10 anos no mercado, encaminhando dependentes químicos e alcoolistas para clínicas
                regularizadas com tratamento humanizado e com muito amor ao próximo,
              </p>
              <p>
                promovendo sua reabilitação, reinserção social, e retorno aos seus familiares,
                enfatizando a valorização pessoal e familiar.
              </p>

              <TitleH4 title="VISÃO" className="fst-italic fs-4 text-start" />
              <p>
                Ser uma clínica de reabilitação acolhedora de dependentes químicos e agente de
                transformação do ser humano.
              </p>

              <TitleH4 title="VALORES" className="fst-italic fs-4 text-start" />
              <ul>
                <li>Compromisso Social;</li>
                <li>Transparência;</li>
                <li>Disciplina;</li>
                <li>Profissionalismo e Confiabilidade;</li>
              </ul>

              <TitleH4 title="PROFISSIONAIS" className="fst-italic fs-4 text-start" />
              <ul>
                <li>Psiquiatras;</li>
                <li>Psicólogos;</li>
              </ul>

              <div className="text-center">
                <Link
                  to={`https://wa.me/${phoneWhatsApp}`}
                  target="_blank"
                  className="btn btn-secondary btn-lg text-white fs-3 px-5">
                  <span>FALAR NO WHATSAPP</span>
                </Link>
              </div>

              {/* <GaleriaQuemSomos images={images} />
              {showModal && (
                <Lightbox
                  mainSrc={arrOfImages[indexOfImages]}
                  nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                  prevSrc={arrOfImages[(indexOfImages + arrOfImages.length - 1) % arrOfImages.length]}
                  onCloseRequest={() => setShowModal(false)}
                  onMovePrevRequest={() =>
                    setIndexOfImages((indexOfImages + arrOfImages.length - 1) % arrOfImages.length)
                  }
                  onMoveNextRequest={() =>
                    setIndexOfImages((indexOfImages + arrOfImages.length + 1) % arrOfImages.length)
                  }
                />
              )} */}
              <div className="text-end mb-3">
                <Link to="/#quem_somos" className="fst-italic">
                  Voltar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Rodape />
    </>
  );
};

export default QuemSomos;
