import './style.scss';
import Lightbox from 'react-image-lightbox';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PropsGalleryGroup } from '../../global/types';
import { phoneWhatsApp } from '../../global/contants';
import { TitleH4 } from '../../components/title';

import Rodape from '../../components/Rodape';
import Topo from '../../components/Topo';

const Galeria = () => {
  const [images, setImages] = useState<PropsGalleryGroup[]>([]);
  const [arrOfImages, setArrOfImages] = useState<string[]>([]);
  const [arrString, setArrString] = useState<string[]>([]);
  const [indexOfImages, setIndexOfImages] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const imageString: string[] = [];
  const galeriaImgs = (int: number, title: string, dir: string) => {
    const arrNumber: number[] = [];
    for (let i: number = 1; i <= int; i++) {
      imageString.push(`./${dir}/img (${i}).jpg`);
      arrNumber.push(i);
    }

    setImages((old) => [...old, { dir, title, images: arrNumber }]);
    setArrString(imageString);
  };

  const handleModalImages = (dir: string) => {
    setIndexOfImages(0);
    setArrOfImages(arrString.filter((item: string) => item.search(dir) > 0));
    setShowModal(true);
    return;
  };

  useEffect(() => {
    // galeriaImgs(
    //   14,
    //   'Clínica em Ribeirão Preto - Masculina',
    //   'galerias/Clinica em Ribeirao Preto - masculina'
    // );
    galeriaImgs(9, 'Clínica em São Paulo - Masculina', 'galerias/Clinica em Sao Paulo - masculina');
    galeriaImgs(10, 'Clínica Itapecerica da Serra', 'galerias/Clinica Itapecerica da Serra');
    galeriaImgs(9, 'Clínica Grajaú - Para menores de idade', 'galerias/Clinica Grajau');
    galeriaImgs(17, 'Clínica São Paulo - Feminina', 'galerias/Clinica Sao Paulo - Feminina');
    galeriaImgs(
      10,
      'Clínica São Roque - aceita convênio médico',
      'galerias/Clinica Sao Roque aceita convenio medico'
    );
    galeriaImgs(23, 'Clínica Sumaré', 'galerias/Clinica Sumare');
    galeriaImgs(10, 'Clínica Unidade Ibaté - Feminina', 'galerias/Clinica Unidade Ibate Feminina');
    galeriaImgs(
      10,
      'Unidade em Grajaú - Masculina - MAIORES DE IDADE',
      'galerias/Unidade em Grajau-SP - Masculina'
    );
    // galeriaImgs(11, 'Unidade Mairinque', 'galerias/Unidade Mairinque');
    galeriaImgs(7, 'Unidade Matão', 'galerias/Unidade Matao');
    galeriaImgs(8, 'Clínica Canoas - Rio Grande do Sul', 'galerias/Clinica canoas rio grande do sul');
    galeriaImgs(19, 'Patrocínio - Minas Gerais', 'galerias/patrocinio - minas gerais');
    galeriaImgs(
      6,
      'Casa de Repouso - Unidade São Paulo',
      'galerias/Casa de Repouso - Unidade Sao Paulo'
    );
  }, []);

  // const InicioGaleria = () => (
  //   <>
  //     <TitleH1 className="mb-5 pb-3 col col-12 border-bottom fst-italic" title="Nossas Unidades" />

  //     <div className="col-12 col-sm-6">
  //       <div className="img">
  //         <img src={`./itapolis/img-(1).jpg`} alt="" />
  //       </div>
  //       <h3 className="fst-italic mb-3">Unidade de Itápolis/SP</h3>
  //       <strong className="d-flex flex-row gap-3">
  //         <span className="d-flex align-items-center">
  //           <i className="fas fa-phone-alt m-2"></i>
  //           (16) 3262-1840
  //         </span>
  //         <a
  //           href="http://wa.me/5516997071840"
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           style={{ textDecoration: 'none' }}
  //           className="d-flex flex-row align-items-center">
  //           <i className="fab fa-whatsapp me-2" style={{ fontSize: '23px' }}></i> (16) 99707-1840
  //         </a>
  //       </strong>
  //       <p className="text-uppercase">AV. FRANCISCO PORTO, 1146 - CENTRO, ITÁPOLIS - SP, 14900-000</p>
  //       <small>
  //         <span
  //           onClick={() => {
  //             galeriaImgs(5, 'Recepção - Itápolis/SP', 'itapolis/recepcao');
  //             galeriaImgs(7, 'Escritório - Itápolis/SP', 'itapolis/escritorio');
  //             galeriaImgs(14, 'Consultórios - Itápolis/SP', 'itapolis/consultorio');
  //             galeriaImgs(4, 'Sala de Esterelização - Itápolis/SP', 'itapolis/sala-de-esterelizacao');
  //           }}
  //           className="btn btn-link fst-italic float-end">
  //           Mais Fotos
  //         </span>
  //         <Link to="/como-chegar/itapolis" className="btn btn-link fst-italic float-end">
  //           Como Chegar
  //         </Link>
  //       </small>
  //     </div>

  //     <div className="col-12 col-sm-6">
  //       <div className="img">
  //         <img src={`./ibitinga/img-(1).jpg`} alt="" />
  //       </div>
  //       <h3 className="fst-italic mb-3">Unidade de Ibitinga/SP</h3>
  //       <strong className="d-flex flex-row gap-3">
  //         <span className="d-flex align-items-center">
  //           <i className="fas fa-phone-alt me-2"></i>
  //           (16) 3342-2810
  //         </span>
  //         <a
  //           href="http://wa.me/5516997360889"
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           style={{ textDecoration: 'none' }}
  //           className="d-flex align-items-center">
  //           <i className="fab fa-whatsapp me-2" style={{ fontSize: '23px' }}></i> (16) 99736-0889
  //         </a>
  //       </strong>
  //       <p className="text-uppercase">Av. Sete de Setembro, 760 - Centro, Ibitinga - SP, 14940-000</p>
  //       <small>
  //         <span
  //           onClick={() => {
  //             galeriaImgs(4, 'Recepção - Ibitinga/SP', 'ibitinga/recepcao');
  //             galeriaImgs(8, 'Escritório - Ibitinga/SP', 'ibitinga/escritorio');
  //             galeriaImgs(8, 'Consultórios - Ibitinga/SP', 'ibitinga/consultorio');
  //           }}
  //           className="btn btn-link fst-italic float-end">
  //           Mais Fotos
  //         </span>
  //         <Link to="/como-chegar/ibitinga" className="btn btn-link fst-italic float-end">
  //           Como Chegar
  //         </Link>
  //       </small>
  //     </div>
  //   </>
  // );

  const GaleriaImages = ({ images }: { images: PropsGalleryGroup[] }) => (
    <>
      <div className="text-end">
        <a className="btn fst-italic btn-link" href="/">
          Voltar
        </a>
      </div>
      {images.map((imgs, i) => (
        <div className="row justify-content-center" key={i}>
          <TitleH4 className="fst-italic my-5 pb-3 col col-12 border-bottom fs-4" title={imgs.title} />
          {imgs.images.map((img, ii) => (
            <div
              key={`${i}${ii}`}
              className="col-12 col-sm-3 img"
              style={{ cursor: 'pointer' }}
              onClick={() => handleModalImages(imgs.dir)}>
              <img
                src={`./${imgs.dir}/img (${img}).jpg`}
                className="img-fluid img-thumbnail rounded"
                alt={imgs.title}
              />
            </div>
          ))}
        </div>
      ))}

      <div className="text-center">
        <Link
          to={`https://wa.me/${phoneWhatsApp}`}
          target="_blank"
          className="btn btn-secondary btn-lg text-white fs-3 px-5">
          <span>FALAR NO WHATSAPP</span>
        </Link>
      </div>

      <div className="text-end">
        <a className="btn fst-italic btn-link" href="/">
          Voltar
        </a>
      </div>
    </>
  );

  if (images.length === 0) return <>Carregando fotos...</>;

  return (
    <>
      <Topo />
      <div className="bg-white border-top pt-5 pb-5">
        <div className="container galeria">
          <div className="row">
            {/* {images.length === 0 ? <InicioGaleria /> : <GaleriaImages images={images} />} */}
            <GaleriaImages images={images} />

            {showModal && (
              <Lightbox
                mainSrc={arrOfImages[indexOfImages]}
                nextSrc={arrOfImages[(indexOfImages + 1) % arrOfImages.length]}
                prevSrc={arrOfImages[(indexOfImages + arrOfImages.length - 1) % arrOfImages.length]}
                onCloseRequest={() => setShowModal(false)}
                onMovePrevRequest={() =>
                  setIndexOfImages((indexOfImages + arrOfImages.length - 1) % arrOfImages.length)
                }
                onMoveNextRequest={() =>
                  setIndexOfImages((indexOfImages + arrOfImages.length + 1) % arrOfImages.length)
                }
              />
            )}
          </div>
        </div>
      </div>
      <Rodape />
    </>
  );
};

export default Galeria;
