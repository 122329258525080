import './style.scss';

import Rodape from '../../components/Rodape';
import Topo from '../../components/Topo';
import { TitleH1, TitleH4 } from '../../components/title';
import { Link } from 'react-router-dom';
import { phoneWhatsApp } from '../../global/contants';

const NossosServicos = () => {
  return (
    <>
      <Topo />
      <div className="bg-white border-top pt-5 pb-5">
        <TitleH1 title="NOSSOS SERVIÇOS" className="fst-bold fs-1 text-center" />

        <div className="equipe-image mb-5" />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 text-justify">
              <TitleH4 title="Amor a vida tratamentos" className="fst-italic fs-4 text-start" />
              <p>Proporcionando um tratamento humanizado, acolhedor com espiritualidade.</p>
              <p>
                <small className="fst-italic">Veja abaixo alguns dos nossos serviços oferecidos:</small>
              </p>
              <ul>
                <li>Atendimento 24 HRS;</li>
                <li>Unidades Masculinas, Femininas e menores de idade</li>
                <li>Privacidade absoluta;</li>
                <li>Segurança e bem estar;</li>
                <li>Ambiente familiar;</li>
                <li>Alimentação saudável e balanceada;</li>
                <li>Áreas comuns e privativas</li>
                <li>Acomodações higiênicas e confortáveis;</li>
                <li>TV;</li>
                <li>Lavanderia;</li>
                <li>Sala de reunião e atendimento individual;</li>
                <li>Piscina;</li>
                <li>Área de lazer e socialização;</li>
                <li>Mesa de Bilhar;</li>
                <li>Campo de Futebol;</li>
                <li>Mesa de Ping Pong;</li>
                <li>Mesa de Pebolim;</li>
                <li>Equipe especializada</li>
                <li>Enfermeiro Padrão;</li>
                <li>Dois Técnicos em Enfermagem;</li>
                <li>Psicólogo;</li>
                <li>Médico;</li>
                <li>Terapeutas;</li>
                <li>Equipe Multidisciplinar</li>
                <li>Coordenador 24 Horas;</li>
                <li>Monitores 24 Horas;</li>
                <li>
                  Vigilância 24 horas, contando com 48 câmeras e sensores de movimento na comunidade;
                </li>
                <li>Plano terapêutico</li>
                <li>12 passos, (A.A./N.A.);</li>
                <li>Plano de Atendimento Singular;</li>
                <li>Grupos de Estudos e Interativos;</li>
                <li>Espiritualidade;</li>
                <li>T.R.E. - Terapia Racional Emotiva;</li>
                <li>P.P.R. - Plano de Prevenção a Recaída;</li>
                <li>T.C.C. - Terapia Comportamental Cognitiva;</li>
                <li>Aconselhamento familiar;</li>
              </ul>
              <div className="text-center">
                <Link
                  to={`https://wa.me/${phoneWhatsApp}`}
                  target="_blank"
                  className="btn btn-secondary btn-lg text-white fs-3 px-5">
                  <span>FALAR NO WHATSAPP</span>
                </Link>
              </div>

              <div className="text-end mb-3">
                <Link to="/" className="fst-italic">
                  Voltar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Rodape />
    </>
  );
};

export default NossosServicos;
