import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

type PropsFilpLink = {
  to: string;
  label: string;
};
const FlipLink: React.FC<PropsFilpLink> = ({ label, to }) => (
  <HashLink to={to}>
    <span>{label}</span>
    <span>{label}</span>
  </HashLink>
);

type PropsFilpBody = {
  hashLink: string;
  handleScroll: () => void;
  children: React.ReactNode;
};
const FlipBody: React.FC<PropsFilpBody> = ({ hashLink, handleScroll, children }) => {
  const { hash } = useLocation();

  const resolveActive = hash === hashLink;

  return (
    <span className="flex-fill flip" data-active={resolveActive} onClick={handleScroll}>
      {children}
    </span>
  );
};

export const Flip = {
  link: FlipLink,
  body: FlipBody
};
